<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].lightblue }" id="app">
        <v-overlay v-if="jingle" z-index="9999" opacity="1" :color="$vuetify.theme.themes[theme].darkblue">
            <video v-if="jingle.includes('.mp4')" ref="jingleRef" autoplay muted @play="unmuteJingle" @ended="hideJingle">
                <source :src="'/video/leaderboard/' + jingle" type="video/mp4">
            </video>
            <v-btn v-else @click="hideJingle" color="pink" x-large>
                Accéder au leaderboard
            </v-btn>
        </v-overlay>

        <v-main>
            <v-container class="py-6 px-6" fluid>
                <v-row class="statsRow">
                    <v-col cols="3">
                        <v-card rounded="lg" :color="$vuetify.theme.themes[theme].mediumblue">
                            <v-card-text class="d-flex flex-column justify-center">
                                <div class="rank mt-2 pa-2 px-4 d-flex align-center" v-for="(user, index) in ranking.slice(0, 3)">
                                    <span style="font-size:24px; color: #1E4169;"><b>#{{ index + 1 }}</b></span>
                                    <v-avatar size="40" class="mx-4">
                                        <v-img :src="api+'/user/'+user.id+'/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                                    </v-avatar>
                                    <span style="font-size:20px; color: #1E4169;">
                                        <b>{{ user.firstname }} {{ user.name }}</b><br>
                                        <small>{{ user.entities.map(entity => entity.text).join(', ') }}</small>
                                    </span>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col v-for="(stat, indexName) in globalStats" :key="'global_'+(stat.name ? stat.name : indexName)">
                        <v-card rounded="lg" :color="$vuetify.theme.themes[theme].mediumblue">
                            <v-card-text class="d-flex flex-column justify-center align-center">
                                <v-icon :color="stat.color" size="60">${{ stat.icon }}</v-icon>
                                <div class="value"><b>{{ stat.value }}</b></div>
                                <p class="textValue">{{ $t(stat.name ? stat.name : indexName) }}</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row class="tablesRow">
                    <v-col cols="12">
                        <v-card rounded="lg" :color="$vuetify.theme.themes[theme].mediumblue" ref="cardTable">
                            <v-card-text class="pa-0">
                                <v-tabs v-model="tabId" class="py-4 px-4" color="white" background-color="transparent" dark hide-slider>
                                    <v-tab v-for="(table, tableName) in tables" :key="'tab_'+tableName" @click="launchInterval">
                                        {{ $t(tableName) }}
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tabId">
                                    <v-tab-item v-for="(table, tableName) in tables" :key="tableName">
                                        <v-simple-table :height="height" fixed-header style="background-color: #2A4F79;">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th>{{ $t('vendor') }}</th>
                                                        <th>{{ $t('sellingPoint_sLabel') }}</th>
                                                        <template v-if="table[0]">
                                                            <th class="text-center" v-for="headerName in Object.keys(table[0])" v-if="!['vendor', 'entities', 'rate'].includes(headerName)" :key="headerName">
                                                                {{ $t(headerName) }}
                                                            </th>
                                                        </template>
                                                        <th class="text-center">{{ $t('rate_' + tableName) }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(vendor, vendorKey) in table" :key="vendorKey">
                                                        <td class="text-center">#{{ vendorKey + 1 }}</td>
                                                        <td>
                                                            <v-avatar size="35" class="mr-4">
                                                                <v-img :src="api + '/user/' + vendor.vendor.value + '/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                                                            </v-avatar>
                                                            {{ vendor.vendor.text }}
                                                        </td>
                                                        <td>
                                                            {{ vendor.vendor.entities.map(entity => entity.text).join(', ') }}
                                                        </td>
                                                        <template v-if="table[0]">
                                                            <td class="text-center" v-for="(value, headerName) in vendor" v-if="!['vendor', 'entities', 'rate'].includes(headerName)" :key="headerName">
                                                                {{ value }}
                                                            </td>
                                                        </template>
                                                        <td class="text-center" style="width:200px;">
                                                            <div class="d-flex align-center">
                                                                <v-progress-linear rounded :value="Math.round(vendor.rate)" height="8" color="success" background-color="white" ></v-progress-linear>
                                                                <span class="ml-4" style="font-size:20px;">{{ Math.round(vendor.rate) }}%</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import rankingCoachs from "@/components/rankingCoachs.vue";

export default {
    name:"OperationDetailLeaderboard",
    components: { rankingCoachs },
    data() {
        return {
            api: this.$store.state.auth.apiUrl,
            rand: Math.random(),
            interval: "",
            tabId: 0,
            ranking : [],
            height: 490,
            globalStats: {},
            tables: [],
            jingle: "coucou",
        }
    },
    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
    },
    created() {
        this.getLeaderboard();
        this.launchInterval();
    },
    watch: {
        ranking() {
            this.$nextTick(() => {
                if(this.$refs.cardTable)
                    this.height = this.$refs.cardTable.$el.clientHeight - 80;
            });
        },
        globalStats(newStats, oldStats) {
            if(oldStats.hasOwnProperty('successes') && newStats.successes.value > oldStats.successes.value) {
                this.showJingle('goal_deal.mp4');
            } else if(oldStats.hasOwnProperty('rdvs') && newStats.rdvs.value > oldStats.rdvs.value) {
                this.showJingle('goal_rdv.mp4');
            } 
        }
    },
    methods: {
        getLeaderboard() {
            GenericDataService.getData('ns/operation/getLeaderboardByToken?token=' + this.$route.params.token).then((response) => {
                this.globalStats = response.data.data.globalStats;
                this.ranking = response.data.data.ranking;
                this.tables = response.data.data.tables;
            }).catch((e) => {});
        },
        showJingle(jingle) {
            if(!this.jingle)
                this.jingle = jingle;
        },
        hideJingle() {
            this.jingle = false;
        },
        unmuteJingle() {
            this.$refs.jingleRef.muted = false;
        },
        launchInterval() {
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.getLeaderboard();
                this.tabId = this.tabId + 1 >= Object.keys(this.tables).length ? 0 : this.tabId + 1;
            }, 10000);
        }
    },
}
</script>

<style lang="scss" scoped>
    #app {
        .container {
            display: flex;
            flex-direction: column;
            height: 100vh;

            .statsRow {
                flex: 0;

                .v-card {
                    .v-card__text {
                        color: #fff;
                        min-height: 270px; 

                        .value {
                            margin: 50px 0px;
                            font-size: 70px;
                            font-weight: 800;
                        }
                        .textValue {
                            margin-bottom: 0px;
                            text-transform: uppercase;
                            font-size: 24px;
                        }
                    }
                }
            }
            .tablesRow {
                flex: 1;

                .v-card {
                    height: 100%;

                    .v-card__text {
                        overflow:hidden;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;

                        .v-tab {
                            text-transform: capitalize;
                            font-size: 18px;
                            margin: 0px 10px;
                        }
                        .v-tab--active {
                            border: 1px solid #fff;
                            border-radius: 50px;
                        }
                        .v-tab:hover::before {
                            opacity: 0;
                        }

                        .v-data-table {
                            border-radius:0px !important;
                            overflow: hidden;

                            ::-webkit-scrollbar-track {
                                -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
                                background-color: #F5F5F5;
                            }
                            ::-webkit-scrollbar {
                                width: 8px;
                                background-color: #F5F5F5;
                            }
                            ::-webkit-scrollbar-thumb {
                                border-radius: 5px;
                                background-color: #1E4169;
                            }
                            .v-data-table__wrapper {
                                overflow-y:auto !important;
                            }

                            thead {
                                tr {
                                    th {
                                        font-size: 20px;
                                        color: rgb(30, 65, 105);
                                    }
                                    height: 50px;
                                }
                            }

                            tbody {
                                color: white;
                                
                                tr {
                                    td {
                                        font-size: 20px;
                                    }
                                    height: 60px;
                                }
                                tr:nth-child(even) {
                                    background-color: #294f79;
                                }
                                tr:nth-child(odd) {
                                    background-color: #5B7798;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
